@font-face {
    font-family: HarmoniaSans;
    font-weight: 300;
    src: url(/fonts/HarmoniaSansStd-Regular.woff);
}

@font-face {
    font-family: HarmoniaSans;
    font-weight: normal;
    src: url(/fonts/HarmoniaSansStd-Regular.woff);
}

@font-face {
    font-family: HarmoniaSans;
    font-weight: 600;
    src: url(/fonts/HarmoniaSansStd-SemiBd.woff);
}

@font-face {
    font-family: HarmoniaSans;
    font-weight: bold; /* 400 */
    src: url(/fonts/HarmoniaSansStd-Bold.woff);
}
