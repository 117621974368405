body {
  margin: 0;
  padding: 0;
  font-family: HarmoniaSans, sans-serif;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

#root {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f2faff;
}

a, a:visited, a:active, a:hover{
  color: inherit;
  text-decoration: none;
  outline: 0;
}